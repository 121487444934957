import * as React from "react"
// import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
// import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'


const OthereventsEventPage = ({ data }) => {

	const page = data.wpEventType;

	return (
		<Layout title={page.title} breadcrumbs={[['Events','events'], [page.title, '#']]}>
			<Seo post={page} />
			<section className="content">
				<div id="pageContent" dangerouslySetInnerHTML={{ __html: page.content }}/>
			</section>
		</Layout>
	)
}

export default OthereventsEventPage

export const query = graphql`
	query OthereventsEventQuery {
		wpEventType(databaseId: {eq: 2850}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;